var render = function render(){var _vm=this,_c=_vm._self._c;return _c('product-manager',{attrs:{"show-categories":_vm.showCategories,"show-product-inital":_vm.value.productsNumber,"products-ids":_vm.productIds},scopedSlots:_vm._u([{key:"default",fn:function({
		loading,
		categories,
		toggleCategory,
		isChoosenCategory,
		products,
		numberOfPages,
		currentPage,
		changePage
	}){return [_c('section',{staticClass:"product-list"},[_c('base-container',[_c('base-font',{attrs:{"variant":"section-header","tag":_vm.value.header_tag}},[_vm._v(" "+_vm._s(_vm.value.heading)+" ")]),_c('div',{staticClass:"product-list-content"},[(_vm.showCategories)?_c('div',{staticClass:"category"},[_c('base-font',{staticClass:"category-filter",attrs:{"size":"sm","weight":"bold","uppercase":""}},[_vm._v("filtruj wg. kategorii:")]),_c('div',{staticClass:"category-wrapper"},_vm._l((categories),function(category){return _c('div',{key:category.id,staticClass:"category-item",class:{'category-item-active': isChoosenCategory(category)},on:{"click":function($event){return toggleCategory(category)}}},[_c('base-font',{staticClass:"category-item-name",attrs:{"uppercase":""}},[_vm._v(" "+_vm._s(category.name)+" ")])],1)}),0)],1):_vm._e(),(loading)?_c('div',{staticClass:"products-wrapper"},_vm._l((3),function(item,i){return _c('skeleton-loader',{key:i,attrs:{"isVisible":loading}},[_c('div')])}),1):_c('div',{staticClass:"products-wrapper"},_vm._l((products),function(product){return _c('product-list-item',{key:product.id,attrs:{"product":product}})}),1),_c('div',{staticClass:"product-list-navigation"},[(_vm.pagination)?_c('base-pagination',{attrs:{"pages":numberOfPages,"currentPage":currentPage},on:{"page-change":changePage}}):_vm._e()],1)])],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }