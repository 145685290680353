<template>
	<product-manager
		:show-categories="showCategories"
		:show-product-inital="value.productsNumber"
		:products-ids="productIds"

		v-slot="{
			loading,
			categories,
			toggleCategory,
			isChoosenCategory,
			products,
			numberOfPages,
			currentPage,
			changePage
		}"
	>
		<section class="product-list">
			<base-container>
				<base-font variant="section-header" :tag="value.header_tag">
					{{ value.heading }}
				</base-font>
				<div class="product-list-content">
					<div class="category" v-if="showCategories">
						<base-font class="category-filter" size="sm" weight="bold" uppercase>filtruj wg. kategorii:</base-font>
						<div class="category-wrapper">
							<div
								v-for="category in categories"
								:key="category.id"
								class="category-item"
								:class="{'category-item-active': isChoosenCategory(category)}"
								@click="toggleCategory(category)"
							>
								<base-font uppercase class="category-item-name">
									{{category.name}}
								</base-font>
							</div>
						</div>
					</div>

						<div v-if="loading" class="products-wrapper">
							<skeleton-loader v-for="(item, i) in 3" :key="i"  :isVisible="loading">
								<div></div>
							</skeleton-loader>
						</div>
						<div v-else class="products-wrapper">
							<product-list-item v-for="product in products" :key="product.id" :product="product"/>
						</div>
					<div class="product-list-navigation">
						<base-pagination v-if="pagination" :pages="numberOfPages" :currentPage="currentPage" @page-change="changePage"/>
					</div>
				</div>
			</base-container>
		</section>
	</product-manager>
</template>

<script>
import ProductManager from '~/shop/front/components/ProductManager'
import ProductListItem from '~/shop/front/components/ProductListItem'
import BasePagination from '~/website/front/components/organisms/BasePagination'
import SkeletonLoader from '~/website/front/components/molecules/loaders/SkeletonLoader'

export default {
	props: {
		prefix: String,
		value: Object
	},
	components: {
		ProductManager,
		ProductListItem,
		SkeletonLoader,
		BasePagination
	},
	computed: {
		pagination () {
			return this.value.type === 'with_categories'
		},
		showCategories () {
			return this.value.type === 'with_categories'
		},
		productIds () {
			if (this.value.type !== 'choosen_products') return false
			return this.value.value.products
		}
	}
}
</script>
<style lang="scss" scoped>
.products-wrapper {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 1.5rem;
	@include media-breakpoint-up(md) {
		grid-gap: 2rem;
	}
	@include media-breakpoint-up(lg) {
		grid-template-columns: 1fr 1fr 1fr;
	}
	@include media-breakpoint-up(xl) {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
}

.product-list-navigation {
	display: flex;
	justify-content: flex-end;
}

.category {

	&-wrapper {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 5rem;

	}
	&-item {
		background: $white;
		cursor: pointer;
		padding: 1rem 2rem;
		margin-top: 1rem;
		margin-right: 2rem;
		transition: 0.3s all;
		user-select: none;

		@include media-breakpoint-up(xl){
			padding: 1.5rem 3rem;
			margin-right: 3rem;
			margin-top: 2rem;
		}

		@media (hover: hover) {
			&:hover {
				background: $gray-200;
			}
		}
		&-active {
			background: $green-700;
			:deep(.category-item-name) {
				color: $white !important;
			}
			&:hover {
				background: $green-800;
			}
		}
	}
}
</style>
