<script>
import { serialize } from 'utils/strings'
import { isPageFromNull } from 'utils/ssrCheck'

export default {
	props: {
		showCategories: Boolean,
		showProductInital: {
			type: Number,
			default: 8
		},
		productsIds: [Array, Boolean]
	},
	async prefetch () {
		await this.loadSettingsFromQuery()

		if (process.server) {
			await this.fetchProducts()

			if (this.showCategories) await this.fetchCategories()

			this.loading = false

			this.$app.page.componentsData.product_manager = {}
			this.$app.page.componentsData.product_manager.products = this.products
			this.$app.page.componentsData.product_manager.numberOfPages = this.numberOfPages
			this.$app.page.componentsData.product_manager.currentPage = this.currentPage
			this.$app.page.componentsData.product_manager.choosenCategories = this.choosenCategories
			this.$app.page.componentsData.product_manager.categories = this.categories
		}
	},
	data: () => ({
		loading: true,
		products: [],
		categories: [],
		choosenCategories: [],
		numberOfPages: 1,
		currentPage: 1,
		location: {}
	}),
	methods: {
		async fetchProducts () {
			const Product = this.$app.getEntity('product')
			const fetchOptions = {
				take: this.showProductInital,
				skip: this.currentPage * this.showProductInital - this.showProductInital,
				order: {
					order: 'ASC'
				}
			}
			if (this.choosenCategories.length) {
				fetchOptions.where = {
					category: this.choosenCategories
				}
			}
			if (this.productsIds) {
				fetchOptions.where = {
					id: this.productsIds
				}
			}

			const { products, numberOfAllProducts } = await Product.fetch(fetchOptions)
			this.products = products
			this.numberOfPages = Math.ceil(numberOfAllProducts / this.showProductInital)
		},
		async fetchCategories () {
			const Category = this.$app.getEntity('category')
			this.categories = await Category.fetch({
				app: this.$app
			})
		},
		async changePage (page) {
			this.currentPage = page

			this.updatePageQuery()

			await this.fetchProducts()

			window.scroll({ top: 0, left: 0, behavior: 'smooth' })
		},
		async toggleCategory (category) {
			const isChoosen = this.isChoosenCategory(category)

			if (isChoosen) this.choosenCategories = this.choosenCategories.filter(item => item !== category.id)
			else this.choosenCategories.push(category.id)

			this.currentPage = 1

			this.updatePageQuery()

			await this.fetchProducts()
		},
		isChoosenCategory (category) {
			return this.choosenCategories.includes(category.id)
		},
		async loadSettingsFromQuery () {
			await this.$nextTick()
			const { category, page } = this.$route.query
			if (category) this.choosenCategories = category.split(',').map(item => Number(item))
			if (page) this.currentPage = Number(page)
		},
		updatePageQuery () {
			const query = {
				page: this.currentPage
			}

			if (this.choosenCategories.length) query.category = this.choosenCategories.join(',')

			history.replaceState(
				{},
				null,
				this.$route.path + serialize(query)
			)
		},
		recreateDataFromComponentsData () {
			const Product = this.$app.getEntity('product')
			this.products = this.$app.page.componentsData.product_manager.products.map(product => Product.create(product))

			const Category = this.$app.getEntity('category')
			this.categories = this.$app.page.componentsData.product_manager.categories.map(category =>
				Category.create(category))
		}
	},
	async mounted () {
		await this.loadSettingsFromQuery()
		if (isPageFromNull(this.$app)) this.recreateDataFromComponentsData()
		else {
			await this.fetchProducts()

			if (this.showCategories) await this.fetchCategories()

			this.loading = false
		}
	},
	render () {
		return this.$scopedSlots.default({
			loading: this.loading,
			products: this.products,
			categories: this.categories,
			numberOfPages: this.numberOfPages,
			currentPage: this.currentPage,
			changePage: this.changePage,
			isChoosenCategory: this.isChoosenCategory,
			toggleCategory: this.toggleCategory
		})
	}
}
</script>
